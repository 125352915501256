<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >正则表达式在线验证</h2>
		<el-row style="margin-top: 4%;">
			<el-col :sm="11" style="margin: 0 auto;">
				<el-form :model="regexpData">
					<el-form-item label="">
						<el-input type="textarea" :rows="5" v-model="regexpData.text" resize='none'></el-input>
					</el-form-item>
					<el-row>
						<el-col>
							<el-form-item label="常用正则">
								<el-radio-group v-model="regexpData.checkunit" size="small" @change="setRegexpText()">
								  <el-radio-button label="1">手机号(国内)</el-radio-button>
								  <el-radio-button label="2">身份证号</el-radio-button>
								  <el-radio-button label="3">电话号码</el-radio-button>
								  <el-radio-button label="4">Email</el-radio-button>
								  <el-radio-button label="5">邮编</el-radio-button>
								  <el-radio-button label="6">帐号</el-radio-button>
								  <el-radio-button label="7">密码(强密码)</el-radio-button>
								  <el-radio-button label="8">域名</el-radio-button>
								  <el-radio-button label="9">ip地址</el-radio-button>
								  <el-radio-button label="10">url地址</el-radio-button>
								  <el-radio-button label="11">正整数</el-radio-button>
								</el-radio-group>
							</el-form-item> 
						</el-col>
					</el-row>
					<el-row>
						<el-col :sm = "24">
							<el-form-item label="">
								<el-input v-model="regexpData.textPattern" placeholder="请输入表达式">
									<template v-slot:prepend>表达式</template>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-if="regexpData.successShow">
						<el-col :sm = "24">
							<el-alert title="验证通过" type="success"  center :closable="false"  show-icon></el-alert>
						</el-col>
					</el-row>
					<el-row v-if="regexpData.warningShow">
						<el-col :sm = "24">
							<el-alert title="验证失败" type="warning"  center :closable="false"  show-icon></el-alert>
						</el-col>
					</el-row>
					<div style="margin: 0 auto; text-align: center; margin-top:15px">
						<el-button id="1001" type="primary" @click="validate()">验证</el-button>
						<el-button  @click="copy(regexpData.textPattern)" type="primary" plain>复制</el-button>
					</div>
					<el-row style="margin-top:15px">
						<el-col :sm = "24">
							<el-form-item label="">
								<el-input readonly type="textarea" :rows="regexpData.rows" v-model="regexpData.result" resize='none'></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
					  <section class="content element-doc content">
						  <h3 id="ji-ben-yong-fa">正则表达式</h3>
						  <p>正则表达式，又称规则表达式,（Regular Expression，在代码中常简写为regex、regexp或RE），是一种文本模式，包括普通字符（例如，a 到 z 之间的字母）和特殊字符（称为"元字符"），是计算机科学的一个概念。正则表达式使用单个字符串来描述、匹配一系列匹配某个句法规则的字符串，通常被用来检索、替换那些符合某个模式（规则）的文本。</p>
					  </section>
					</el-row>
					<el-row>
						  <h3 id="ji-ben-yong-fa">表达式语法</h3>
						  <el-table :data="regexpData.tableData" style="width: 100% background: oldlace" :row-class-name="warning-row">
							  <el-table-column prop="number" label="序号" width="80"></el-table-column>
							  <el-table-column prop="code" label="代码" width="240"></el-table-column>
							  <el-table-column prop="depict" label="说明"></el-table-column>
							</el-table>
					</el-row>
				
				</el-form>
		</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	

    let regexpData = ref({
		moduleid:2004,
        rows: 10,
        checkunit: "1",
		successShow:false,
		warningShow:false,
		regexp:"",
		tableData: [
			    {
		          number: '1',
		          code: '.',
		          depict: '	匹配除换行符以外的任意字符',
		        },
				{
			    number: '2',
			    code: '\\w', // eslint-disable-line
			    depict: '匹配字母或数字或下划线',
				},
				{
				number: '3',
				code: '\\s', // eslint-disable-line
				depict: '匹配任意的空白符',
				},
				{
				number: '4',
				code: '\\d', // eslint-disable-line
				depict: '匹配数字',
				},
				{
				number: '5',
				code: '\\b', // eslint-disable-line
				depict: '匹配单词的开始或结束',
				},
				{
				number: '6',
				code: '^', // eslint-disable-line
				depict: '匹配字符串的开始',
				},
				{
				number: '7',
				code: '$', // eslint-disable-line
				depict: '匹配字符串的结束',
				},
				{
				number: '8',
				code: '*', // eslint-disable-line
				depict: '重复零次或更多次',
				},
				{
				number: '9',
				code: '+', // eslint-disable-line
				depict: '重复一次或更多次',
				},
				{
				number: '10',
				code: '?', // eslint-disable-line
				depict: '重复零次或一次',
				},
				{
				number: '11',
				code: '{n}', // eslint-disable-line
				depict: '重复n次',
				},
				{
				number: '12',
				code: '{n,}', // eslint-disable-line
				depict: '重复n次或更多次',
				},
				{
				number: '13',
				code: '{n,m}', // eslint-disable-line
				depict: '重复n到m次',
				},
				{
				number: '14',
				code: '\W', // eslint-disable-line
				depict: '匹配任意不是字母，数字，下划线，汉字的字符',
				},
				{
				number: '15',
				code: '\S', // eslint-disable-line
				depict: '匹配任意不是空白符的字符',
				},
				{
				number: '16',
				code: '\D', // eslint-disable-line
				depict: '匹配任意非数字的字符',
				},
				{
				number: '17',
				code: '\B', // eslint-disable-line
				depict: '匹配不是单词开头或结束的位置',
				},
				{
				number: '18',
				code: '[^x]', // eslint-disable-line
				depict: '匹配除了x以外的任意字符',
				},
				{
				number: '19',
				code: '[^aeiou]', // eslint-disable-line
				depict: '匹配除了aeiou这几个字母以外的任意字符',
				}
				]
    })
	moduleaction(regexpData.value.moduleid,1);
	
	//验证正则
    let validate = () => {
		regexpData.value.successShow=false;
		regexpData.value.warningShow=false;
		var pattern=regexpData.value.textPattern;
		var sourceText=regexpData.value.text;
		if(!sourceText){
			regexpData.value.result="请输入要验证的文本";
			return
		}
		if(!pattern){
			regexpData.value.result="请输入正则表达式";
			return
		}
		var result =sourceText.match(new RegExp(pattern,"g"));
		if (null == result || 0 == result.length) {
			regexpData.value.warningShow=true;
		    regexpData.value.result="（没有匹配）";
		    return;
		    }
		var strResult = "共找到 " + result.length + " 处匹配：\r\n";
		for (var i = 0; i < result.length; ++i) strResult = strResult + result[i] + "\r\n";
		regexpData.value.result= strResult;
		if(sourceText==result){regexpData.value.successShow=true}else{regexpData.value.warningShow=true}
		//模块事件 1.预览 2.使用
		moduleaction(regexpData.value.moduleid,2);
    }
	
	let setRegexpText = () => {
		regexpData.value.textPattern=getRegexp(regexpData.value.checkunit);
	}
	
	let getRegexp = (i) => {
		var regexpMap=new Map();
		regexpMap.set("1","^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$")// eslint-disable-line
		regexpMap.set("2","^[1-9]\\d{5}(18|19|([23]\\d))\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$")// eslint-disable-line
		regexpMap.set("3","^\\d{3}-\\d{7,8}|\\d{4}-\\d{7,8}$")// eslint-disable-line
		regexpMap.set("4","^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$")// eslint-disable-line
		regexpMap.set("5","[1-9]\\d{5}(?!\\d)")// eslint-disable-line
		regexpMap.set("6","^[a-zA-Z][a-zA-Z0-9_]{4,15}$")
		regexpMap.set("7","^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,10}$")// eslint-disable-line
		regexpMap.set("8","^([a-z0-9][a-z0-9-]*?.(?:com|cn|net|org|gov|info|la|cc|co)(?:.(?:cn|jp))?)$")
		regexpMap.set("9","\\d+\\.\\d+\\.\\d+\\.\\d+")// eslint-disable-line
		regexpMap.set("10","^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$")// eslint-disable-line
		regexpMap.set("11","^[0-9]*[1-9][0-9]*$")// eslint-disable-line
		return regexpMap.get(i);
	}
	setRegexpText();	
</script>

<style>

</style>